import globalVar from "../utils/globalVar";

class RecommendedProducts {
  constructor() {
    let swiperInstance = null;

    let initFunc = () => {
      initSwiper();
    };

    const initSwiper = () => {
      if (globalVar.tablet.matches) {
        if (!swiperInstance) {
          swiperInstance = new Swiper(".slider-recommended-products", {
            slidesPerView: 1,
            spaceBetween: 10,
            loop: true,
            pagination: {
              el: ".swiper-pagination",
              clickable: true,
            },
          });
        }
      } else {
        if (swiperInstance) {
          swiperInstance.destroy(true, true);
          swiperInstance = null;
        }
      }
    };

    initFunc();
  }
}

export { RecommendedProducts };
