class Home {
	constructor() {
		let cache = {};
		let initFunc = () => {
			initCache();
			registerEvents();
		};

		let initCache = () => {
			cache.page = document.querySelector(".template-home");
			cache.popupAlert = document.querySelector(".popup-alert");
			cache.closePopupAlert = document.getElementById("close-popup-alert");
		};

		let registerEvents = () => {
			if (cache.closePopupAlert) {
				cache.closePopupAlert.addEventListener("click", closePopupAlert);
			}
		};

		let closePopupAlert = () => {
			if (cache.popupAlert.classList.contains("open")) {
				cache.popupAlert.classList.remove("open");
			} else {
				cache.popupAlert.classList.add("open");
			}
		};

		initFunc();
	}
}
export { Home };
