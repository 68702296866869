class LoadMoreProducts {
	constructor() {
		let cache = {};
		let initFunc = () => {
			initCache();
			registerEvents();
		};

		let initCache = () => {
			cache.loadMoreBtnBikes = document.getElementById("load-more-bikes");
			cache.bikesContainer = document.querySelector(
				"#bikes-products ul.products"
			);

			cache.loadMoreBtnAddons = document.getElementById("load-more-addons");
			cache.addonsContainer = document.querySelector(
				"#addons-products ul.products"
			);

			cache.loadMoreBtnSpareParts = document.getElementById(
				"load-more-spare-parts"
			);
			cache.sparePartsContainer = document.querySelector(
				"#spare-parts-products ul.products"
			);
		};

		let registerEvents = () => {
			if (cache.loadMoreBtnBikes && cache.bikesContainer) {
				cache.loadMoreBtnBikes.addEventListener("click", loadMoreBikes);
			}
			if (cache.loadMoreBtnAddons && cache.addonsContainer) {
				cache.loadMoreBtnAddons.addEventListener("click", loadMoreAddons);
			}
			if (cache.loadMoreBtnSpareParts && cache.sparePartsContainer) {
				cache.loadMoreBtnSpareParts.addEventListener(
					"click",
					loadMoreSpareParts
				);
			}
		};

		let loadMoreBikes = () => {
			let page = parseInt(cache.loadMoreBtnBikes.getAttribute("data-page"));

			cache.loadMoreBtnBikes.disabled = true;

			const xhr = new XMLHttpRequest();
			xhr.open("POST", ajax_object.ajax_url, true);
			xhr.setRequestHeader(
				"Content-Type",
				"application/x-www-form-urlencoded; charset=UTF-8"
			);

			xhr.onload = function() {
				if (xhr.status === 200) {
					const response = JSON.parse(xhr.responseText);
					if (response.success) {
						if (cache.bikesContainer) {
							cache.bikesContainer.insertAdjacentHTML(
								"beforeend",
								response.data
							);
						} else {
							console.error("Le conteneur de produits n'a pas été trouvé.");
						}

						// Incrémente et met à jour l’attribut `data-page` pour la prochaine page
						page++;
						cache.loadMoreBtnBikes.setAttribute("data-page", page);

						if (!response.has_more_products) {
							cache.loadMoreBtnBikes.style.display = "none";
						}

						// Réinitialise les swatches pour les nouveaux produits chargés
						if (
							typeof SW !== "undefined" &&
							typeof SW.firstTime === "function"
						) {
							SW.firstTime();
						}
						if (typeof addVariationFunctionality === "function") {
							addVariationFunctionality();
						}
					} else {
						console.error("Erreur de réponse AJAX:", response);
					}
				}
				cache.loadMoreBtnBikes.disabled = false;
			};

			xhr.send("action=load_more_bikes_products&page=" + page);
		};

		let loadMoreAddons = () => {
			let page = parseInt(cache.loadMoreBtnAddons.getAttribute("data-page"));

			cache.loadMoreBtnAddons.disabled = true;

			const xhr = new XMLHttpRequest();

			xhr.open("POST", ajax_object.ajax_url, true);
			xhr.setRequestHeader(
				"Content-Type",
				"application/x-www-form-urlencoded; charset=UTF-8"
			);

			xhr.onload = function() {
				if (xhr.status === 200) {
					const response = JSON.parse(xhr.responseText);
					if (response.success) {
						if (cache.addonsContainer) {
							cache.addonsContainer.insertAdjacentHTML(
								"beforeend",
								response.data
							);
						} else {
							console.error("Le conteneur de produits n'a pas été trouvé.");
						}

						page++;
						cache.loadMoreBtnAddons.setAttribute("data-page", page);

						if (!response.has_more_products) {
							cache.loadMoreBtnAddons.style.display = "none";
						}
					} else {
						console.error("Erreur de réponse AJAX:", response);
					}
				}
				cache.loadMoreBtnAddons.disabled = false;
			};

			xhr.send("action=load_more_addons_products&page=" + page);
		};

		let loadMoreSpareParts = () => {
			let page = parseInt(
				cache.loadMoreBtnSpareParts.getAttribute("data-page")
			);

			cache.loadMoreBtnSpareParts.disabled = true;

			const xhr = new XMLHttpRequest();
			xhr.open("POST", ajax_object.ajax_url, true);
			xhr.setRequestHeader(
				"Content-Type",
				"application/x-www-form-urlencoded; charset=UTF-8"
			);

			xhr.onload = function() {
				if (xhr.status === 200) {
					const response = JSON.parse(xhr.responseText);
					if (response.success) {
						if (cache.sparePartsContainer) {
							cache.sparePartsContainer.insertAdjacentHTML(
								"beforeend",
								response.data
							);
						} else {
							console.error("Le conteneur de produits n'a pas été trouvé.");
						}

						page++;
						cache.loadMoreBtnSpareParts.setAttribute("data-page", page);

						if (!response.has_more_products) {
							cache.loadMoreBtnSpareParts.style.display = "none";
						}
					} else {
						console.error("Erreur de réponse AJAX:", response);
					}
				}
				cache.loadMoreBtnSpareParts.disabled = false;
			};

			xhr.send("action=load_more_spare_parts_products&page=" + page);
		};

		initFunc();
	}
}

export { LoadMoreProducts };
