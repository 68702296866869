import anime from "../libs/anime";
import globalVar from "../utils/globalVar";

class Header {
	constructor() {
		let cache = {};
		let initFunc = () => {
			initCache();
			registerEvents();
		};

		let initCache = () => {
			cache.header = document.querySelector("header.site-header");
			cache.popupAlert = document.querySelector(".popup-alert");
			cache.lastScrollTop = 0;
		};

		let registerEvents = () => {
			if (cache.popupAlert) {
				adjustHeaderTop();
			}

			window.addEventListener("resize", adjustHeaderTop);

			window.addEventListener("scroll", scrollingWheel);
		};

		let adjustHeaderTop = () => {
			if (globalVar.tablet.matches && cache.popupAlert) {
				cache.header.style.top = "55px";
			} else if (cache.popupAlert) {
				cache.header.style.top = "45px";
			} else {
				cache.header.style.top = ""; // Reset si la popup n'est pas présente
			}
		};

		let scrollingWheel = () => {
			const scrollTop =
				window.pageYOffset || document.documentElement.scrollTop;

			if (globalVar.tablet.matches) {
				if (scrollTop > cache.lastScrollTop) {
					cache.header.style.transform = "translateY(-270%)";
					cache.popupAlert.style.transform = "translateY(-55px)";
				} else {
					cache.header.style.transform = "translateY(0)";
					cache.popupAlert.style.transform = "translateY(-55px)";
				}
			} else {
				if (scrollTop > cache.lastScrollTop) {
					cache.header.style.transform = "translateY(-150%)";
					cache.popupAlert.style.transform = "translateY(-45px)";
				} else {
					cache.header.style.transform = "translateY(0)";
					cache.popupAlert.style.transform = "translateY(-45px)";
				}
			}

			cache.lastScrollTop = scrollTop;
		};

		initFunc();
	}
}
export { Header };
