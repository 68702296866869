class SliderOtherProducts {
  constructor() {
    const swiper = new Swiper(".slider-other-products", {
      centeredSlides: true,
      loop: true,
      pagination: {
        el: ".swiper-pagination",
        clickable: true,
      },
    });
  }
}

export { SliderOtherProducts };
