class Hotspots {
	constructor() {
		let cache = {};
		let initFunc = () => {
			initCache();
			registerEvents();
		};

		let initCache = () => {
			cache.hotspotButtons = document.querySelectorAll(".hotspots-item-btn");
			cache.wrapperHotspots = document.querySelector(".wrapper-hotspots");
			cache.currentPopup = null; 
		};

		let registerEvents = () => {
			cache.hotspotButtons.forEach((button) => {
				const hotspotID = button.closest(".hotspots-item").getAttribute("data-hotspots");
				const popup = document.querySelector(`.hotspots-item-popup[data-hotspots="${hotspotID}"]`);

				button.addEventListener("click", function() {
					if (cache.currentPopup) {
						cache.currentPopup.classList.remove("open");
					}

					popup.classList.add("open");
					cache.currentPopup = popup;
				});

				const closeButton = popup.querySelector(".hotspots-close-btn");
				closeButton.addEventListener("click", function() {
					popup.classList.remove("open");
					cache.currentPopup = null;
				});
			});
		};

		initFunc();
	}
}

export { Hotspots };