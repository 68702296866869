class FilterMob {
	constructor() {
		let cache = {};
		let initFunc = () => {
			initCache();
			registerEvents();
		};

		let initCache = () => {
			cache.filtersToggle = document.querySelector(".toggle-filters");
			cache.filtersContainer = document.querySelector(
                ".mobile-filters-container"
			);
            cache.filtersCloseBtn = document.querySelector(".filters-sidebar .btn_close");
		};

		let registerEvents = () => {
			if (cache.filtersToggle) {
				cache.filtersToggle.addEventListener("click", function() {
					cache.filtersContainer.classList.add("open");
				});
			}
			if (cache.filtersCloseBtn) {
				cache.filtersCloseBtn.addEventListener("click", function() {
					cache.filtersContainer.classList.remove("open");
				});
			}
		};

		initFunc();
	}
}

export { FilterMob };
