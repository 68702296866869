import globalVar from "../utils/globalVar";

class SliderProductGallery {
  constructor() {
    let swiperInstance = null;

    const initFunc = () => {
      registerEvents();
    };

    const registerEvents = () => {
      initSwiper();
      // window.addEventListener("resize", () => initSwiper());
    };

    const initSwiper = () => {
      if (globalVar.tablet.matches) {
        if (!swiperInstance) {
          swiperInstance = new Swiper(".slider-product-gallery", {
            slidesPerView: 1,
            spaceBetween: 10,
            loop: true,
            navigation: {
              nextEl: ".swiper-button-next",
              prevEl: ".swiper-button-prev",
            },
          });
        }
      } else {
        if (swiperInstance) {
          swiperInstance.destroy(true, true);
          swiperInstance = null;
        }
      }
    };

    initFunc();
  }
}

export { SliderProductGallery };
