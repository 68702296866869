import globalVar from "../utils/globalVar";

class TabsBike {
  constructor() {
    let swiperInstance = null;
    let cache = {};
    let initFunc = () => {
      initCache();
      registerEvents();
    };

    let initCache = () => {
      cache.tabs = document.querySelectorAll(".tab-name");
      cache.tabContents = document.querySelectorAll(".tab-content");
    };

    let registerEvents = () => {
      cache.tabs.forEach((tab, index) => {
        tab.addEventListener("click", () => toggleTabs(index));
      });

      initSwiper();
    };

    let toggleTabs = (index) => {
      cache.tabs.forEach((tab) => tab.classList.remove("active"));
      cache.tabContents.forEach((content) =>
        content.classList.remove("active")
      );

      cache.tabs[index].classList.add("active");
      cache.tabContents[index].classList.add("active");
    };

    const initSwiper = () => {
      if (globalVar.tablet.matches) {
        if (!swiperInstance) {
          swiperInstance = new Swiper(".slider-tabs-bike", {
            slidesPerView: 1,
            spaceBetween: 10,
            loop: true,
            pagination: {
              el: ".swiper-pagination",
              clickable: true,
            },
          });
        }
      } else {
        if (swiperInstance) {
          swiperInstance.destroy(true, true);
          swiperInstance = null;
        }
      }
    };

    initFunc();
  }
}

export { TabsBike };
