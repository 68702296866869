class VariationsImg {
	constructor() {
		let cache = {};
		let initFunc = () => {
			initCache();
			registerEvents();
		};

		let initCache = () => {
			cache.variationImages = document.querySelectorAll(".variation-image");
			cache.defaultImage = document.querySelector(".default-image");
			cache.form = document.querySelector(".variations_form");
			cache.resetButton = document.querySelector(".reset_variations");
		};

		let registerEvents = () => {
			if (cache.form && cache.variationImages.length > 0) {
				jQuery(cache.form).on("found_variation", function(event, variation) {
					if (cache.defaultImage) {
						cache.defaultImage.style.display = "none";
					}

					cache.variationImages.forEach(
						(image) => (image.style.display = "none")
					);

					// Affiche l'image de la variation sélectionnée
					const selectedImage = document.querySelector(
						'.variation-image[data-variation-id="' +
							variation.variation_id +
							'"]'
					);
					if (selectedImage) {
						selectedImage.style.display = "block";
					}
				});

				if (cache.resetButton) {
					cache.resetButton.addEventListener("click", function() {
						cache.variationImages.forEach(
							(image) => (image.style.display = "none")
						);

						if (cache.defaultImage) {
							cache.defaultImage.style.display = "block";
						}
					});
				}

				// Réinitialiser les images au changement de variation
				cache.form.addEventListener("reset", function() {
					cache.variationImages.forEach(
						(image) => (image.style.display = "none")
					);

					if (cache.defaultImage) {
						cache.defaultImage.style.display = "block";
					}
				});
			}
		};

		initFunc();
	}
}

export { VariationsImg };
