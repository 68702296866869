class LoginTabs {
    constructor() {
      let cache = {};
      let initFunc = () => {
        initCache();
        registerEvents();
      };
  
      let initCache = () => {
        cache.loginTab = document.getElementById("login-tab");
        cache.registerTab = document.getElementById("register-tab");
        cache.loginForm = document.getElementById("login-form");
        cache.registerForm = document.getElementById("register-form");
      };
  
      let registerEvents = () => {
        cache.loginTab.addEventListener("click", function() {
            cache.loginTab.classList.add("active");
            cache.registerTab.classList.remove("active");
            cache.loginForm.classList.add("active");
            cache.registerForm.classList.remove("active");
        });
    
        cache.registerTab.addEventListener("click", function() {
            cache.registerTab.classList.add("active");
            cache.loginTab.classList.remove("active");
            cache.registerForm.classList.add("active");
            cache.loginForm.classList.remove("active");
        });
      };
  
      initFunc();
    }
  }
  export { LoginTabs };