class ReadMore {
	constructor() {
		let cache = {};
		let initFunc = () => {
			initCache();
			registerEvents();
		};

		let initCache = () => {
			cache.readMoreButtons = document.querySelectorAll(".read-more-btn");
		};

		let registerEvents = () => {
			cache.readMoreButtons.forEach((button) => {
				const description = button
					.closest(".description-wrapper")
					.querySelector(".description");

				if (description) {
					button.addEventListener("click", function() {
						description.classList.toggle("limited");
						button.classList.toggle("open");
					});
				}
			});
		};

		initFunc();
	}
}

export { ReadMore };
