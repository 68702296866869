class SliderQuotes {
	constructor() {
		const cache = {};
		let count = 0;

		const initFunc = () => {
			initCache();
			registerEvents();
		};

		const initCache = () => {
			cache.sliderQuotes = document.querySelector(".slider-quotes");
			cache.slides = document.querySelectorAll(".slider-quotes .swiper-slide");
			cache.logos = document.querySelectorAll(
				".slider-quotes .swiper-slide .image"
			);
		};

		const registerEvents = () => {
			initSlider();
		};

		const initSlider = () => {
			const slideCount = cache.slides.length;

			if (cache.sliderQuotes && slideCount > 1) {
				const swiper = new Swiper(".slider-quotes", {
					centeredSlides: true,
					loop: true,
					pagination: {
						el: ".swiper-pagination",
						clickable: true,
					},
				});
			} else {
				cache.logos.forEach((logo) => {
					logo.style.justifyContent = "flex-start";
				});
			}
		};
        
		initFunc();
	}
}

export { SliderQuotes };
